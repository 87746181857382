<template>
  <div class="login">
    <div class="login_form">
      <el-form
        :model="form"
        status-icon
        :rules="rules"
        ref="form"
        class="demo-ruleForm"
      >
        <el-form-item style="width: 100%" prop="user_name">
          <el-input v-model="form.user_name" placeholder="用户名"></el-input>
        </el-form-item>
        <el-form-item style="width: 100%" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>

        <el-form-item
          prop="passwords"
          oninput="value=value.replace(/[^\d]/g,'')"
        >
          <el-col :span="14">
            <el-input
              oninput="value=value.replace(/[^\d]/g,'')"
              maxlength="6"
              type="text"
              v-model="form.code"
              placeholder="请输入验证码"
              style="width: 100%"
            ></el-input>
          </el-col>
          <el-col :span="6" class="count_down" style="margin-left: 10px">
            <el-button
              style="background-color: rgb(156, 44, 44); color: #fff"
              v-if="disabled == false"
              @click="sendcode"
              class="huoqu"
              >获取验证码</el-button
            >
            <el-button
              disabled="disabled"
              type="info"
              v-if="disabled == true"
              @click="sendcode"
              >{{ btntxt }}</el-button
            >
          </el-col>
        </el-form-item>
        <el-form-item prop="phones">
          <el-input
            maxlength="20"
            type="password"
            v-model="form.password"
            placeholder="请输入密码"
            style="width: 100%"
          />
        </el-form-item>
      </el-form>
      <div @click="sup" class="sup">提交</div>
    </div>
  </div>
</template>

<script>
export function checkPhoneNumber(rule, value, callback) {
  const reg = /^1[3|4|5|6|7|8|9]\d{9}$/;
  if (!value) {
    return callback(new Error("请填写手机号码！"));
  } else if (!reg.test(value)) {
    return callback(new Error("请填写正确的手机号码！"));
  } else {
    callback();
  }
}
// 手机号 密码
export function passPhone(rule, value, callback) {
  if (value == "") {
    return callback(new Error("密码不能为空"));
  } else {
    return callback();
  }
}
import { sms_send, forget } from "../axios/api.js";
export default {
  data() {
    return {
      btntxt: "",
      disabled: false,
      form: {
        user_name: "",
        mobile: "",
        code: "",
        password: "",
        // token: sessionStorage.getItem("Token"),
      },
      rules: {
        mobile: [
          { required: true, validator: checkPhoneNumber, trigger: "blur" },
        ],
        user_name: [
          { required: true, message: "请填写用户名", trigger: "blur" },
        ],
      },
    };
  },

  components: {},

  methods: {
    sup() {
      console.log(234);
      let params = this.form;
      forget(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.$router.push({ path: "/login" });
        } else {
          this.$message.error({
            message: res.data.msg,
          });
        }
      });
    },
    sendcode() {
      const reg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
      console.log(this.form.mobile);
      if (this.form.mobile == "") {
        this.$message({
          message: "手机号不能为空",
          type: "error",
        });
        return;
      }
      if (!reg.test(this.form.mobile)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "error",
        });
        return;
      } else {
        console.log(123);
        let params = {
          mobile: this.form.mobile,
        };
        console.log(params);
        this.time = 60;
        this.disabled = true;
        this.timer();
        sms_send(params)
          .then((res) => {
            console.log(res);
            let { msg, code } = res;
            if (res.data.code !== 200) {
              this.$message({
                message: "发送失败",
                type: "error",
              });
              this.time = 60;
              this.disabled = true;
              this.timer();
            } else {
              this.$message({
                message: "发送成功",
                type: "success",
              });
              this.time = 60;
              this.disabled = true;
              this.timer();
            }
          })
          .catch(function (reason) {
            //console.log('catch:', reason);
          });
      }
    },
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.disabled = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.login {
  width: 100%;
  height: 100%;
  background: url("../assets/static/img/background.jpg") no-repeat 100%;
  background-size: cover;
  overflow: hidden;
  .login_form {
    position: absolute;
    top: 50%;
    right: 15%;
    transform: translateY(-50%);
    width: 500px;
    height: 440px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .username {
      display: flex;
      align-items: center;
      width: 100%;
      height: 70px;
    }
    .userpws {
      display: flex;
      align-items: center;
      width: 100%;
      height: 70px;
    }

    .icon {
      width: 70px;
      height: 70px;
      border-right: 1px solid #999999;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ckb {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .mima {
        color: red;
        cursor: pointer;
      }
    }
    .ckb > div {
      display: flex;
      align-items: center;
      color: #999;
      font-size: 13px;
    }
    .ckb > div:nth-child(1) {
      width: 60%;
      input {
        background: 1px solid #999;
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
    .btn {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: #890510;
    }
    .foter {
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
.bor {
  border: 1px solid red;
  box-shadow: 1px 0px 5px red;
}
input::placeholder {
  color: #999999;
}
.sup {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  background-color: #890510;
  font-size: 14px;
}
.huoqu {
  font-size: 13px;
}
</style>